<template>
  <div>
    <el-card>
      <el-button
        type="primary"
        class="btnAdd"
        @click="newlist"
        style=" float: right; "
        v-show="$store.state.btn_if.indexOf('ad_list_add') != -1"
        >新增</el-button
      >
      <nav class="out">
        <nav class="input" style="width: 150px">
          <el-select placeholder="位置" v-model="types" size="medium">
            <el-option label="全部" :value="-1"></el-option>
            <el-option label="首页" :value="0"></el-option>
            <el-option label="资讯页" :value="1"></el-option>
            <el-option label="快讯页" :value="2"></el-option>
            <el-option label="资讯详情页" :value="3"></el-option>
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select placeholder="类型" v-model="sourceTypes" size="medium">
            <el-option label="全部" :value="-1"></el-option>
            <el-option label="文章" :value="1"></el-option>
            <el-option label="活动" :value="2"></el-option>
            <el-option label="报告" :value="3"></el-option>
            <el-option label="跳转链接" :value="4"></el-option>
          </el-select>
        </nav>
        <nav class="input" style="width: 150px">
          <el-select placeholder="状态" v-model="isShow" size="medium">
            <el-option label="全部" :value="-1"></el-option>
            <el-option label="隐藏 " :value="0"></el-option>
            <el-option label="显示" :value="1"></el-option>
          </el-select>
        </nav>

        <nav class="input">
          <!-- <el-button @click="reset" size="small">重置</el-button> -->
          <el-button @click="subimt" type="primary" size="small">{{
            btn ? '搜索' : 'loading..'
          }}</el-button>
          <el-button @click="rest" size="small">{{ '重置' }}</el-button>
        </nav>
      </nav>
    </el-card>

    <el-card style="margin-top: 10px">
      <el-table
        :data="list"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @sort-change="sortChange"
      >
        <el-table-column prop="id" width="100" label="编号"> </el-table-column>
        <el-table-column prop="sourceType" label="类型" width="180">
          <template slot-scope="ew">
            {{ ew.row.sourceType | gettype }}
          </template>
        </el-table-column>

        <el-table-column prop="orderNum" label="排序" sortable="custom">
          <template #header>
            <span>排序</span>
            <el-tooltip effect="dark" placement="top">
              <div slot="content">数值越大，显示越靠前</div>
              <i class="el-icon-warning-outline" style="margin-left: 5px;"></i>
            </el-tooltip>
          </template>
          <template slot-scope="{ row, $index }">
            <div class="input-box">
              <el-input
                v-show="row.pai"
                :ref="'INPUTFOCUS' + $index"
                v-model="row.orderNum"
                size="small"
                class="id"
                :autofocus="row.pai"
                @blur="handleInputBlur(row)"
              />
            </div>
            <span
              v-show="!row.pai"
              :class="
                $store.state.btn_if.indexOf('ad_list_order') != -1 &&
                  'el-icon-edit'
              "
              @click="
                $store.state.btn_if.indexOf('ad_list_order') != -1 &&
                  showinput(row, 'INPUTFOCUS' + $index)
              "
            >
              {{ row.orderNum }}</span
            >
          </template>
        </el-table-column>
        <el-table-column prop="type" label="位置">
          <template slot-scope="{ row }">
            <p v-if="row.type == 1">资讯页</p>
            <p v-if="row.type == 2">快讯页</p>
            <p v-if="row.type == 3">资讯详情页</p>
            <p v-if="row.type == 0">首页</p>
            <!-- <p v-else>小图</p> -->
          </template>
        </el-table-column>
        <el-table-column prop="isShow" label="状态">
          <template #header>
            <span>状态</span>
            <el-tooltip effect="dark" placement="top">
              <div slot="content">
                如果对应的文章，活动等状态变动为不显示状态，<br />则对应banner位置也会被隐藏
              </div>
              <i class="el-icon-warning-outline" style="margin-left: 5px;"></i>
            </el-tooltip>
          </template>
          <template slot-scope="ew">
            <span v-if="ew.row.isShow == 1">显示中</span>
            <span v-else>已隐藏</span>
          </template>
        </el-table-column>
        <el-table-column prop="type" label="跳转链接">
          <template slot-scope="{ row }">
            <a :href="row.url">{{ row.url }}</a>
          </template>
        </el-table-column>
        <el-table-column prop="banner" label="图片">
          <template slot-scope="{ row }">
            <img :src="baseurl + yu + row.logo" alt="" style="width: 150px" />
          </template>
        </el-table-column>
        <!-- <el-table-column prop="orderNum" label="排序" sortable="custom">
          <template slot-scope="scope">
            <div class="input-box">
              <el-input
                size="small"
                @blur="
                  handleInputBlur(
                    scope.$index,
                    scope.row.id,
                    scope.row.orderNum
                  )
                "
                v-model="scope.row.orderNum"
                v-if="pai[scope.$index].pai"
                class="id"
              ></el-input>
            </div>
            <span
              v-if="!pai[scope.$index].pai"
              @click="showinput(scope.$index)"
              class="el-icon-edit"
            >
              {{ scope.row.orderNum }}</span
            >
          </template>
        </el-table-column> -->

        <el-table-column prop="createTime" label="时间" sortable="custom">
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="ew">
            <el-button
              type="text"
              icon="el-icon-edit"
              size="mini"
              @click="edit(ew.row.id)"
              class="skyblue"
              v-show="$store.state.btn_if.indexOf('ad_list_edit') != -1"
              >编辑</el-button
            >
            <el-button
              type="text"
              icon="el-icon-delete"
              size="mini"
              @click="open(ew.row.id)"
              class="red"
              v-show="$store.state.btn_if.indexOf('ad_list_delete') != -1"
              >删除</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        background
        :page-sizes="[3, 5, 10, 15]"
        :page-size="pagesize"
        layout="total, prev, pager, next"
        :total="total"
      >
      </el-pagination>
    </el-card>
    <el-dialog title="新增快讯" :visible.sync="dialogVisible" width="50%">
      <el-form ref="form" :model="form" label-width="80px">
        <el-form-item label="快讯标题">
          <el-input v-model="form.title"></el-input>
        </el-form-item>
        <el-form-item label="来源">
          <el-input v-model="form.source"></el-input>
        </el-form-item>
        <el-form-item label="配图">
          <el-upload
            class="avatar-uploader"
            :action="this.$store.getters.imgUrl"
            :headers="header"
            :show-file-list="false"
            :on-success="handleAvatarSuccess"
            :data="action"
          >
            <img v-if="form.picture" :src="form.picture" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="内容">
          <el-input v-model="form.content" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button
          @click="
            dialogVisible = false
            form = {}
          "
          >取 消</el-button
        >
        <el-button type="primary" @click="add">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog title="资讯修改" :visible.sync="dialogVisiblexui" width="50%">
      <el-form ref="form" :model="form_1" label-width="80px">
        <el-form-item label="快讯标题">
          <el-input v-model="form_1.title"></el-input>
        </el-form-item>
        <el-form-item label="来源">
          <el-input v-model="form_1.source"></el-input>
        </el-form-item>
        <el-form-item label="配图">
          <el-upload
            class="avatar-uploader"
            :show-file-list="false"
            :headers="header"
            :action="this.$store.getters.imgUrl"
            :on-success="handleAvatarSuccess_1"
            :data="action"
          >
            <img v-if="form_1.picture" :src="form_1.picture" class="avatar" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
        <el-form-item label="来源">
          <el-input v-model="form_1.content" type="textarea"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisiblexui = false">取 消</el-button>
        <el-button type="primary" @click="addedit">{{
          btn ? '确 定' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
    <el-dialog title="快讯列表" :visible.sync="diokuai" width="50%">
      <el-table
        :data="lista"
        style="width: 100%"
        border
        stripe
        :header-cell-style="rowClass"
        @selection-change="sel"
      >
        <!-- <el-table-column prop="id" width="100" label="编号"> </el-table-column> -->
        <el-table-column type="selection" width="55"> </el-table-column>
        <el-table-column prop="title" label="标题" width="180">
        </el-table-column>
        <el-table-column prop="content" label="内容"> </el-table-column>
        <el-table-column prop="source" label="来源"> </el-table-column>
        <el-table-column prop="originLink" label="链接"> </el-table-column>
        <el-table-column prop="createTime" label="创建时间" sortable>
        </el-table-column>
      </el-table>
      <el-pagination
        background
        layout="total, prev, pager, next"
        :current-page.sync="currentPagea"
        :total="totala"
        :page-size="pagesizea"
        @size-change="handleSizeChangea"
        @current-change="handleCurrentChangea"
      >
      </el-pagination>
      <span slot="footer" class="dialog-footer">
        <el-button @click="diokuai = false">取 消</el-button>
        <el-button type="primary" @click="addskuai">{{
          btn ? '添 加' : 'loading..'
        }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  // 111
  filters: {
    gettype: function(value) {
      if (value == 1) {
        return '文章'
      } else if (value == 2) {
        return '活动'
      } else if (value == 3) {
        return '报告'
      } else {
        return '跳转链接'
      }
    }
  },
  data() {
    return {
      action: {
        action: 'system'
      },
      isShow: '',
      order: '',
      sourceType: '',
      sourceTypes: '',
      btn: true,
      currentPage: 1,
      pagesize: 10,
      currentPagea: 1,
      pagesizea: 5,
      list: [],
      value: '',
      totala: 0,
      dialogVisible: false,
      dialogVisiblexui: false,
      baseurl: this.$store.state.env.cos,
      yu: '/' + this.$store.state.env.domain,
      total: 0,
      type: '',
      types: '',
      form: {
        title: '',
        source: '',
        picture: '',
        content: ''
      },
      form_1: {
        id: '',
        title: '',
        source: '',
        picture: '',
        content: ''
      },
      imageUrl: '',
      imageUrl_1: '',
      header: {
        Token: sessionStorage.getItem('token')
      },
      diokuai: false,
      lista: [],
      fastid: '',
      paiform: {},
      pai: []
    }
  },
  updated() {
    //   console.log(this.form);
  },
  methods: {
    async handleInputBlur(row) {
      this.paiform = row
      try {
        const { data: res } = await this.$http.post(
          '/admin/HomeAd/edit',
          this.paiform
        )
        if (res.errorCode == 200) {
          this.$message.success(res.message)
          //  row.pai=false
          //  this.tableData=[...this.tableData]
          this.getlist()
        } else {
          this.$message.error(res.message)
        }
      } catch (e) {
        // this.$message.error(e)
      }
    },
    sortChange(c) {
      console.log(c)
      if (c.order) {
        var s = 'activity_' + c.prop
        if (c.prop == 'orderNum') {
          s = 'ha_order_num'
        } else {
          s = 'ha_create_time'
        }
        var p = c.order.slice(0, c.order.length - 6)
        this.order = s + ' ' + p
      } else {
        this.order = ''
      }

      this.getlist()
    },
    rowClass() {
      return 'background:#F5F7FA;'
    },
    newlist() {
      this.$router.push('/ad')
    },
    handleSizeChange(e) {
      this.pagesize = e
      console.log(e)
    },
    handleCurrentChange(e) {
      this.currentPage = e
      this.getlist()
    },
    handleSizeChangea(e) {
      this.pagesizea = e
      console.log(e)
    },
    handleCurrentChangea(e) {
      this.currentPagea = e
      this.getlista()
    },
    showinput(row, refName) {
      console.log(row, refName, this.$refs[refName])
      row.pai = true
      this.list = [...this.list]
      setTimeout(() => {
        $('.id input').focus()
      }, 0)
    },
    subimt() {
      this.currentPage = 1
      this.getlist()
    },
    rest() {
      this.types = ''
      this.sourceTypes = ''
      this.isShow = ''
      this.getlist()
    },
    getlist() {
      this.$http
        .get('/admin/HomeAd/getList', {
          params: {
            page: this.currentPage,
            pageSize: this.pagesize,
            type: this.types,
            sourceType: this.sourceTypes,
            order: this.order,
            isShow: this.isShow
          }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.list = res.data.list
            this.total = res.data.totalCount
            this.list.forEach(item => {
              this.pai.push({ id: item.id, pai: false })
            })
            console.log(this.list)
          } else {
            this.$message.error(res.message)
          }
        })
    },
    handleAvatarSuccess(res, file) {
      if (res.errorCode == 200) {
        this.form.picture = this.baseurl + res.data.url
      } else {
        this.$message.error(res.message)
      }
      console.log(file)
      //   console.log(imgurlbase)
    },
    sel(val) {
      console.log(val)
      var se = val.map(item => item.id)
      console.log(se)

      this.fastid = se.join(',')
    },
    // beforeAvatarUpload(file) {
    //   const isJPG = file.type === 'image/jpeg'
    //   const isLt2M = file.size / 1024 / 1024 < 2

    //   if (!isJPG) {
    //     this.$message.error('上传头像图片只能是 JPG 格式!')
    //   }
    //   if (!isLt2M) {
    //     this.$message.error('上传头像图片大小不能超过 2MB!')
    //   }
    //   return isJPG && isLt2M
    // },
    handleAvatarSuccess_1(res, file) {
      if (res.errorCode == 200) {
        this.form_1.picture = this.baseurl + res.data.url
      } else {
        this.$message.error(res.message)
      }
      console.log(file)
      //   console.log(imgurlbase)
    },
    beforeAvatarUpload_1(file) {
      const isJPG = file.type === 'image/jpeg'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!')
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 2MB!')
      }
      return isJPG && isLt2M
    },
    // handleInputBlur(id, ids, orderNum) {
    //   this.paiform.id = ids
    //   this.paiform.orderNum = orderNum
    //   this.$http
    //     .post('/admin/Report/sort', this.paiform)
    //     .then(({ data: res }) => {
    //       if (res.errorCode == 200) {
    //         this.$message.success(res.message)
    //       } else {
    //         this.$message.error(res.message)
    //       }
    //     })
    //   this.pai[id].pai = false
    // },
    add() {
      if (this.btn) {
        if (this.form.picture) {
          this.form.picture = this.form.picture.slice(this.baseurl.length)
        }
        this.$http.post('/admin/News/add', this.form).then(res => {
          if (res.status == 200) {
            this.$message.success('操作成功')
            this.dialogVisible = false
            location.reload()
          } else {
            this.$message.error(res.data.message)
          }
          this.btn = true
        })
      }
      this.btn = false
    },
    addedit() {
      if (this.btn) {
        this.form_1.picture = this.form_1.picture.slice(this.baseurl.length)
        this.$http
          .post('/admin/News/edit', this.form_1)
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.dialogVisiblexui = false
              this.getlist()
            } else {
              this.$message.error(res.message)
            }
            this.btn = true
          })
      }
      this.btn = false
    },
    edit(id) {
      // this
      this.$router.push({ path: '/ad', query: { id: id } })
    },
    open(id) {
      this.$confirm('此操作将永久删除该, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this.$http
            .delete('/admin/HomeAd/del', { params: { id: id } })
            .then(res => {
              //   console.log(res)
              if (res.data.errorCode == 200) {
                this.$message({
                  type: 'success',
                  message: '删除成功!'
                })
                if (this.total % 10 == 1) {
                  this.currentPage--
                }
                this.getlist()
              }
            })
        })
        .catch(() => {
          this.$message({
            type: 'info',
            message: '已取消删除'
          })
        })
    },
    show(id, el) {
      this.$http
        .post('/admin/News/publish', { id: id, publishStatus: el })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.$message.success(res.message)
            this.getlist()
          } else {
            this.$message.error(res.message)
          }
        })
    },
    addkuai() {
      this.diokuai = true
      this.getlista()
    },
    getlista() {
      this.$http
        .get('/admin/NewsTemp/getList', {
          params: { page: this.currentPagea, pageSize: this.pagesizea }
        })
        .then(({ data: res }) => {
          if (res.errorCode == 200) {
            this.lista = res.data.list
            this.totala = res.data.totalCount
            console.log(this.currentPagea)
            console.log(this.pagesizea)
          } else {
            this.$message.error(res.message)
          }
        })
    },
    addskuai() {
      if (this.btn) {
        this.$http
          .post('/admin/News/addAll', { ids: this.fastid })
          .then(({ data: res }) => {
            if (res.errorCode == 200) {
              this.$message.success(res.message)
              this.diokuai = false
              location.reload()
            } else {
              this.$message.error(res.message)
            }
            this.btn = true
          })
      }
      this.btn = false
    }
  },
  created() {
    this.getlist()
    sessionStorage.setItem('action', 'news')
  }
}
</script>

<style lang="less" scoped>
.out {
  // width: 500px;

  margin-bottom: 20px;
  .input {
    width: 37%;
    display: inline-block;
    margin-right: 30px;
  }
}
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.skyblue {
  color: skyblue;
}
.green {
  color: green;
}
.yellow {
  color: pink;
}
</style>
